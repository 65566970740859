.bg {
  background: url(assets/images/remira-bg-light.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  background-color: #fff!important;
  width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-white {
  background: rgba(255,255,255,.8)!important;
    border-radius: 16px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255,255,255,.3);
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.k-button-solid-primary {
  background-color: #39B4BF !important;
  border-color: #39B4BF !important;
}